import { template as template_c736562b413d427ea3558d9535959e1d } from "@ember/template-compiler";
const FKControlMenuContainer = template_c736562b413d427ea3558d9535959e1d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
